.modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__content {
  max-width: 832px;
  width: max-content;
  width: max-content;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  overflow-y: scroll;
  max-height: 90vh;
}
